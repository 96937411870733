<template>
  <footer class="py-5 bg-black">
    <div class="container">
      <p class="text-center text-muted mb-4 small">
        Disclaimer:

        Tezos and ꜩ are the rights of the Tezos Foundation and not us, and are referenced here to showcase the tools, libraries, &amp; integrations that Tezos offers.
        Usage of this website and interactions with the blockchain are your responsibility, use with care!
      </p>
      <p class="text-center text-white">
        Like what we're doing? Send a donation to
        <strong>tz1Vb19E2Hh4JcerACeF1AJPkPSL63d5KAcF</strong> or
        <strong>dos-esposas.tez</strong>!
      </p>
      <p class="text-center text-white">
        Have junk tokens you don't want anymore? Send to
        <strong>tz1Wq2Y9Fn2kAByDaCtkw14avAbAroJaatnC</strong> or
        <strong>dumpster.tez</strong> and any proceeds from liquidations go to support continued Tezos related development!
      </p>
      <p class="text-center text-muted mb-4">
        We <span class="text-danger">&#x2764;</span> Tezos!
      </p>
      <p class="text-center text-white m-0 small">Copyright&nbsp;© Dos Esposas Restaurant Group 2021</p>
    </div>
  </footer>
</template>
