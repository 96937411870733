<template>
  <h1>Not yet.</h1>
</template>

<script>
export default {
  name: 'Profile'
}
</script>

<style scoped>
</style>
