<template>
  <div class="leader">
    <img src="assets/img/crying-taco.gif">
    <h1>404 Not Found</h1>
    <p>We scoured the blockchain but can't find anything. Try your request again!</p>
  </div>
</template>

<script>
export default {
  name: 'PageNotFound'
}
</script>

<style scoped>
.leader {
  width: 100%;
  padding-top: 140px;
  padding-bottom: 50px;
  text-align: center;
}
</style>
